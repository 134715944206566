import TagsResponse from 'common/types/TagsResponse';
import { BASE_URL } from './constants';
import authenticatedFetch from './util/authenticatedFetch';

/**
 * Get all available tags
 */
export async function getTags(): Promise<TagsResponse> {
  const response = await authenticatedFetch(`${BASE_URL}/tags`);

  return response.json();
}
