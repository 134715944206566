/* eslint-disable react/no-array-index-key */
import { getTags } from 'common/api/tags';
import { updateTrial } from 'common/api/trials';
import { TAG_CATEGORIES } from 'common/constants';
import Tag from 'common/types/Tag';
import Trial from 'common/types/Trial';
import TrialIntervention from 'common/types/TrialIntervention';
import TrialLocation from 'common/types/TrialLocation';
import TrialResponse from 'common/types/TrialResponse';
import getTagIdsByCategory from 'common/util/getTagIdsByCategory';
import getTagsByCategory from 'common/util/getTagsByCategory';
import Button from 'components/Button';
import EligibilityCriteriaEdit from 'components/EligibilityCriteriaEdit';
import InputField from 'components/InputField/InputField';
import InterventionEdit from 'components/InterventionEdit';
import LabeledMultiSelect from 'components/LabeledMultiSelect';
import LabeledTextArea from 'components/LabeledTextArea';
import LocationSummaryList from 'components/LocationSummaryList';
import ResourceLinks from 'components/ResourceLinks';
import SectionSubheading from 'components/SectionSubheading';
import StatusIndicator from 'components/StatusIndicator';
import TextButton from 'components/TextButton';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledTrial = styled.main`
  padding-top: 60px;
  padding-bottom: 60px;
`;

const StyledTrialContent = styled.div`
  ${(props) => props.theme.paddedContent}

  max-width: 800px;
  margin: 0 auto;
`;

const StyledBackLink = styled(TextButton)`
  margin-bottom: 60px;
  text-decoration: none;

  color: ${(props) => props.theme.colors.altLightButtonText};
`;

const StyledTitle = styled.h3`
  font-family: ${(props) => props.theme.fonts.main};
`;

const StyledLoading = styled.h4`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 250px;

  font-family: ${(props) => props.theme.fonts.main};
  text-align: center;
`;

const StyledLoadingError = styled.h4`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 250px;

  font-family: ${(props) => props.theme.fonts.main};
  color: ${(props) => props.theme.colors.error};
  text-align: center;
`;

const StyledStatus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 30px;
`;

const StyledStatusLabel = styled(SectionSubheading)`
  padding-right: 10px;
`;

const StyledTextContent = styled.p`
  white-space: pre-line;
  word-wrap: break-word;
`;

const StyledDetailsSection = styled.section`
  margin-top: 60px;
  margin-bottom: 60px;
`;

const StyledSubSection = styled.section`
  margin-top: 30px;
  margin-bottom: 30px;
`;

const StyledError = styled.div`
  margin-bottom: 20px;
  color: ${(props) => props.theme.colors.error};
`;

const StyledSuccess = styled.div`
  margin-bottom: 20px;
  color: ${(props) => props.theme.colors.successText};
`;

export type RouteMatchParams = {
  id?: string;
};

export type TrialPageProps = {
  loadTrial: () => Promise<TrialResponse>;
  theme?: DefaultTheme;
};

type FormState = {
  objective_description: string;
  detailed_description: string;
  trial_interventions: TrialIntervention[];
  inclusion: string[];
  exclusion: string[];
  locations: TrialLocation[];
  is_sponsored: boolean;
  stageTags: number[];
  phaseTags: number[];
  typeTags: number[];
  otherTags: number[];
};

// Component
const TrialPage = ({ loadTrial }: TrialPageProps) => {
  const { t } = useTranslation(['trial']);
  const history = useHistory();
  const match = useRouteMatch<RouteMatchParams>();

  const {
    params: { id = null },
  } = match;

  const lastLocation = useLastLocation();
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [trial, setTrial] = useState<Trial | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<any | null>(null);
  const [availableTags, setAvailableTags] = useState<Tag[]>([]);

  const [formState, setFormState] = useState<FormState>({
    objective_description: '',
    detailed_description: '',
    trial_interventions: [],
    inclusion: [],
    exclusion: [],
    locations: [],
    is_sponsored: false,
    stageTags: [],
    phaseTags: [],
    typeTags: [],
    otherTags: [],
  });

  // TODO: Revisit data loading
  useEffect(() => {
    const callLoadTrial = async () => {
      try {
        const trialResponse = await loadTrial();
        const tagsResponse = await getTags();
        setTrial(trialResponse.data);
        setAvailableTags(tagsResponse.tags);
      } catch (ex) {
        setLoadingError(ex);
      } finally {
        setIsLoading(false);
      }
    };

    callLoadTrial();
  }, [id, loadTrial]);

  useEffect(() => {
    if (!trial) {
      return;
    }
    setFormState({
      objective_description: trial.objective_description,
      detailed_description: trial.detailed_description.textblock ?? '',
      trial_interventions: trial.interventions,
      inclusion: trial.eligibility.criteria.inclusion,
      exclusion: trial.eligibility.criteria.exclusion,
      locations: trial.locations,
      is_sponsored: trial.is_sponsored ?? false,
      stageTags: getTagIdsByCategory(
        availableTags,
        TAG_CATEGORIES.STAGE,
      ).filter((tagId) => trial.tags.includes(tagId)),
      phaseTags: getTagIdsByCategory(
        availableTags,
        TAG_CATEGORIES.PHASE,
      ).filter((tagId) => trial.tags.includes(tagId)),
      typeTags: getTagIdsByCategory(availableTags, TAG_CATEGORIES.TYPE).filter(
        (tagId) => trial.tags.includes(tagId),
      ),
      otherTags: getTagIdsByCategory(
        availableTags,
        TAG_CATEGORIES.UNCATEGORIZED,
      ).filter((tagId) => trial.tags.includes(tagId)),
    });
  }, [trial, availableTags]);

  if (isLoading) {
    return (
      <StyledTrial>
        <StyledLoading>{t('trial:loading_trial')}</StyledLoading>
      </StyledTrial>
    );
  }

  if (loadingError || !trial || !id) {
    return (
      <StyledTrial>
        <StyledLoadingError>
          {t('trial:error_loading_trial')}
        </StyledLoadingError>
      </StyledTrial>
    );
  }

  // Destructure the trial details
  const {
    brief_title: briefTitle,
    overall_status: overallStatus,

    conditions = [],
    detailed_description: { textblock: detailedDescription = null } = {},
    links = [],
  } = trial ?? {};

  const submit = async () => {
    setError(null);

    const { stageTags, phaseTags, typeTags, otherTags, ...rest } = formState;
    const tags = [...stageTags, ...phaseTags, ...typeTags, ...otherTags];

    const submitResult = await updateTrial(id, {
      ...rest,
      tags,
    });

    if (!submitResult || !submitResult.success) {
      setError(t('trial:unknown_error'));
      return;
    } else {
      setSuccess(t('trial:success'));
      setTimeout(function () {
        setSuccess(null);
      }, 5000);
    }
  };

  return (
    <StyledTrial>
      <StyledTrialContent>
        {lastLocation !== null && (
          <StyledBackLink onClick={() => history.goBack()}>
            {t('trial:back_to_results')}
          </StyledBackLink>
        )}
        <StyledTitle>{briefTitle}</StyledTitle>
        <StyledStatus>
          <StyledStatusLabel>{t('trial:status')}</StyledStatusLabel>
          <StatusIndicator status={overallStatus!} />
        </StyledStatus>

        {conditions && conditions.length > 0 && (
          <StyledSubSection>
            <SectionSubheading>{t('trial:conditions_label')}</SectionSubheading>
            <p>{conditions.join(', ')}</p>
          </StyledSubSection>
        )}

        <InputField
          checkboxLabel={t('trial:is_sponsored')}
          type="checkbox"
          checked={formState.is_sponsored}
          onChangeCheckbox={(newValue) => {
            setFormState({ ...formState, is_sponsored: newValue });
          }}
        />

        <StyledSubSection>
          <SectionSubheading>{t('trial:tags')}</SectionSubheading>
          <LabeledMultiSelect<number>
            defaultValue={t('trial:empty_tags')}
            label={t('tag_stage')}
            options={getTagsByCategory(availableTags, TAG_CATEGORIES.STAGE).map(
              (tag) => ({
                label: tag.name,
                value: tag.id,
              }),
            )}
            values={formState.stageTags ?? []}
            showOther={false}
            onValuesChange={(values: number[]) =>
              setFormState({
                ...formState,
                stageTags: values,
              })
            }
          />
          <LabeledMultiSelect<number>
            defaultValue={t('trial:empty_tags')}
            label={t('tag_phase')}
            options={getTagsByCategory(availableTags, TAG_CATEGORIES.PHASE).map(
              (tag) => ({
                label: tag.name,
                value: tag.id,
              }),
            )}
            values={formState.phaseTags ?? []}
            showOther={false}
            onValuesChange={(values: number[]) =>
              setFormState({
                ...formState,
                phaseTags: values,
              })
            }
          />
          <LabeledMultiSelect<number>
            defaultValue={t('trial:empty_tags')}
            label={t('tag_type')}
            options={getTagsByCategory(availableTags, TAG_CATEGORIES.TYPE).map(
              (tag) => ({
                label: tag.name,
                value: tag.id,
              }),
            )}
            values={formState.typeTags ?? []}
            showOther={false}
            onValuesChange={(values: number[]) =>
              setFormState({
                ...formState,
                typeTags: values,
              })
            }
          />
          <LabeledMultiSelect<number>
            defaultValue={t('trial:empty_tags')}
            label={t('tag_other')}
            options={getTagsByCategory(
              availableTags,
              TAG_CATEGORIES.UNCATEGORIZED,
            ).map((tag) => ({
              label: tag.name,
              value: tag.id,
            }))}
            values={formState.otherTags ?? []}
            showOther={false}
            onValuesChange={(values: number[]) =>
              setFormState({
                ...formState,
                otherTags: values,
              })
            }
          />
        </StyledSubSection>

        <StyledSubSection>
          <SectionSubheading>
            {t('trial:interventions_label')}
          </SectionSubheading>
          <InterventionEdit
            interventionArray={formState.trial_interventions}
            onChangeIntervention={(newValue: TrialIntervention[]) => {
              setFormState({
                ...formState,
                trial_interventions: newValue,
              });
            }}
          />
        </StyledSubSection>

        <StyledSubSection>
          <LocationSummaryList
            locations={formState.locations}
            onChangeLocation={(newValue: TrialLocation[]) => {
              setFormState({
                ...formState,
                locations: newValue,
              });
            }}
          />
        </StyledSubSection>

        {links && links.length > 0 && (
          <StyledSubSection>
            <ResourceLinks links={links} isMainSection />
          </StyledSubSection>
        )}

        {detailedDescription && (
          <StyledDetailsSection>
            <StyledTextContent>
              <LabeledTextArea
                value={formState.detailed_description}
                label={t('trial:detailed_description')}
                height="500px"
                onChange={(evt) => {
                  setFormState({
                    ...formState,
                    detailed_description: evt.target.value,
                  });
                }}
              />
            </StyledTextContent>
          </StyledDetailsSection>
        )}

        <StyledDetailsSection>
          <StyledTextContent>
            <LabeledTextArea
              value={formState.objective_description}
              label={t('trial:objective_description')}
              height="200px"
              onChange={(evt) => {
                setFormState({
                  ...formState,
                  objective_description: evt.target.value,
                });
              }}
            />
          </StyledTextContent>
        </StyledDetailsSection>

        <StyledSubSection>
          <SectionSubheading>{t('trial:inclusion_criteria')}</SectionSubheading>
          <EligibilityCriteriaEdit
            eligibilityCriteriaArray={formState.inclusion}
            onChange={(newValue: string[]) => {
              setFormState({
                ...formState,
                inclusion: newValue,
              });
            }}
          />
        </StyledSubSection>

        <StyledSubSection>
          <SectionSubheading>{t('trial:exclusion_criteria')}</SectionSubheading>
          <EligibilityCriteriaEdit
            eligibilityCriteriaArray={formState.exclusion}
            onChange={(newValue: string[]) => {
              setFormState({
                ...formState,
                exclusion: newValue,
              });
            }}
          />
        </StyledSubSection>

        <StyledError>{error}</StyledError>
        <StyledSuccess>{success}</StyledSuccess>

        <Button dark onClick={submit}>
          {t('common:submit')}
        </Button>
      </StyledTrialContent>
    </StyledTrial>
  );
};

export default TrialPage;
